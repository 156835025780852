.border {
  border-bottom: 1px solid;
  border-color: #c4c4c4;
  padding-top: 1%;
}

.delete-modal .MuiPaper-root {
  padding: 4% 4%;
}

.delete-modal-title {
  padding-bottom: 10% !important;
}

.delete-modal-field {
  padding-bottom: 10% !important;
}

.feedback-container {
  border-left: 1px solid;
  border-color: #c4c4c4;
}

.text-field .MuiInputBase-root {
  border-radius: 4px;
  background-color: #f8f8f8;
  border: none;
  font-size: 18px;
  color: #222;
}

.text-field .MuiInputBase-root:hover {
  background-color: #f0f0f0;
}

.text-field .MuiFilledInput-underline::before {
  border: none;
  text-decoration: none;
}

.text-field .MuiFilledInput-underline::after {
  border: none;
  text-decoration: none;
}

.privacy-change {
  color: #8f8f8f;
  text-align: right;
  line-height: 0.9em;
}

.privacy-change-button {
  font-size: 1.2em;
  cursor: pointer;
}

.privacy-desc {
  color: #8f8f8f;
  font-size: 16px;
  line-height: 140%;
}

.settings {
  font-family: Inter;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.settings-changes {
  display: flex;
  flex-direction: row;
  position: relative;
  padding: 2% 5% 0 5%;
}

.settings-delete {
  position: absolute;
  right: 5%;
  padding: 0 0 5% 0;
}

.settings-form {
  padding: 5% 5% 0 5%;
}

.settings-header {
  background: #f8f8f8;
  font-weight: bold;

  padding: 5% 5%;
  margin: 0 0;
}

.settings-privacy {
  display: flex;
  flex-direction: column;
  padding: 0 5% 5% 5%;
}

@media screen and (max-width: 1024px) {
  .settings-privacy {
    padding: 0 5% 30px 5%;
  }
}

.settings-submissions {
  display: contents;
}

.text-field {
  margin: 65% 5%;
}

/* TODO: initialize standardized element-specific styles in App.css*/
h2 {
  font-size: 48px;
  line-height: 0.5em;
}

h3 {
  width: auto;
  height: auto;
}
